import AddClassNameActions from './AddClassNameActions'
import AccessUserData from './AccessUserData'
import AccessModule from './AccessModule'
import AccessPermission from './AccessPermission'
import UploadBriefFile from './UploadBriefFile'
import UploadQuotation from './UploadQuotation'
import UploadAssignmentBrief from './UploadAssignmentBrief'

const Actions = {
  AddClassNameActions,
  AccessUserData,
  AccessModule,
  AccessPermission,
  UploadBriefFile,
  UploadQuotation,
  UploadAssignmentBrief
}

export default Actions