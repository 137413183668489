const showSidebar = () => {
  return {
    type: "SHOWSIDEBAR"
  }
}
const hideSidebar = () => {
  return {
    type: "HIDESIDEBAR"
  }
}
const showTabAll = () => {
  return {
    type: "SHOWTABALL"
  }
}
const showTabClients = () => {
  return {
    type: "SHOWTABCLIENTS"
  }
}
const showTabCompanies = () => {
  return {
    type: "SHOWTABCOMPANIES"
  }
}
const showTabPartners = () => {
  return {
    type: "SHOWTABPERTNERS"
  }
}
const showTabInternals = () => {
  return {
    type: "SHOWTABINTERNALS"
  }
}

export default {
  showSidebar,
  hideSidebar,
  showTabAll,
  showTabClients,
  showTabCompanies,
  showTabPartners,
  showTabInternals
}