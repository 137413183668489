import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
//component
import Application from './components/_components/Application';
import Index from './components/_reducers/Index';
//css
import './index.scss';

let store
if (process.env.ENV === 'production') { store = createStore(Index) }
else {
  store = createStore(
    Index,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
  )
}

const App = () => {
  return (
    pug`
      .App
        Provider(store=store)
          Application
    `
  );
};

const rootElement = document.getElementById("sweetescape-fotto");
ReactDOM.render(<App />, rootElement);