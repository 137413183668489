import React, {lazy, Suspense, useEffect, useState} from 'react';
import { Route, 
         Redirect,
         BrowserRouter as Router,
         Switch
        } 
from 'react-router-dom'
import PrivateRoute from './PrivateRoute';
import Actions from 'Action/Actions'
//redux
import { useDispatch, useSelector } from "react-redux";
//
import { isExpiredSession, logout } from 'Helpers/Function';
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const Login = lazy(() => import("../Login/Login"));
const Forgot = lazy(() => import("../Login/Forgot"));
const ChangePassword = lazy(() => import("../Login/ChangePassword"));
//error
const Error = lazy(() => import("../Global/Error/Error"));
//landing
const LandingHome = lazy(() => import("../Landing/Home/Home"))
const LandingTermsAndPolicy = lazy(() => import("../Landing/TermsAndPolicy/TermsAndPolicy"))
const LandingContact = lazy(() => import("../Landing/Contact/Contact"))
const LandingThankYou = lazy(() => import("../Landing/ThankYou/ThankYou"))
const LandingExpertises = lazy(() => import("../Landing/Expertises/Expertises"))
const LandingExpertisesDetail = lazy(() => import("../Landing/ExpertisesDetail/ExpertisesDetail"))
const LandingPortfolioDetails = lazy(() => import("../Landing/Portfolios/Show"))
const LandingPortfolios = lazy(() => import("../Landing/Portfolios/Portfolios"))
const loading = <div> Loading... </div>

const componentDashboard = () => <Dashboard />
const componentLogin = () => <Login />
const componentForgot = () => <Forgot />
const componentChangePassword = (props) => <ChangePassword {...props}/>
const component404 = () => <Error code={404}/>
const componentLandingHome = () => <LandingHome />
const componentLandingTermsAndPolicy = () => <LandingTermsAndPolicy />
const componentLandingContact = () => <LandingContact />
const componentLandingThankYou = () => <LandingThankYou />
const componentLandingExpertises = () => <LandingExpertises />
const componentLandingExpertisesDetail = () => <LandingExpertisesDetail />
const componentPortfolioDetails = () => <LandingPortfolioDetails />
const componentLandingPortfolios = () => <LandingPortfolios />

const Application = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.AttributesUser)
  useEffect(()=>{
    dispatch(Actions.AccessUserData.getData())
  },[])

  useEffect(()=>{
    if(user.data){
      if (isExpiredSession(user.data.expiry)) { logout() }
      else {
        if (/(login|forgot|change-password)$/.test(window.location.href)) {
          window.location.replace(window.location.origin+"/dashboard");
        }
      }
    }
  },[user])


  return (
    pug`
      Suspense(fallback=loading)
        Router
          if user
            if user.data
              Switch
                Route(exact path="/" render=componentLandingHome)
                Route(exact path="/terms-of-service" render=componentLandingTermsAndPolicy)
                Route(exact path="/privacy-policy" render=componentLandingTermsAndPolicy)
                Route(exact path="/contact-us" render=componentLandingContact)
                Route(exact path="/thank-you" render=componentLandingThankYou)
                Route(exact path="/expertises" render=componentLandingExpertises)
                Route(exact path="/expertises/:name" render=componentLandingExpertisesDetail)
                Route(exact path="/portfolios/:id" render=componentPortfolioDetails)
                Route(exact path="/expertises/:title" render=componentLandingExpertises)
                Route(exact path="/portfolios" render=componentLandingPortfolios)
                // PrivateRoute(exact path="/" component=componentDashboard user=user)
                PrivateRoute(path="/dashboard" component=componentDashboard user=user)
                // Redirect(from="*" to="/dashboard/home")
                Route(path="*" render=component404)
            else
              Switch
                Route(exact path="/" render=componentLandingHome)
                Route(exact path="/terms-of-service" render=componentLandingTermsAndPolicy)
                Route(exact path="/privacy-policy" render=componentLandingTermsAndPolicy)
                Route(exact path="/contact-us" render=componentLandingContact)
                Route(exact path="/thank-you" render=componentLandingThankYou)
                Route(exact path="/expertises" render=componentLandingExpertises)
                Route(exact path="/expertises/:name" render=componentLandingExpertisesDetail)
                Route(exact path="/portfolios/:id" render=componentPortfolioDetails)
                Route(exact path="/expertises/:title" render=componentLandingExpertises)
                Route(exact path="/portfolios" render=componentLandingPortfolios)
                Route(path="/login" render=componentLogin)
                Route(path="/forgot" render=componentForgot)
                Route(path="/change-password" render=(props) => componentChangePassword(props))
                PrivateRoute(path="/dashboard" component=componentDashboard user=user)
                Route(path="*" render=component404)
    `
  );
};

export default Application;