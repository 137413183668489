import {getStorage} from 'Helpers/Function';

let user = getStorage('userData') || null;

const AttributesUser = (state = '', action) => {
  switch(action.type){
    case 'GETDATA': 
      return { data: user ? user[0] : null, role: user ? user[1] : null, token: user ? user[2] : null }
    default: 
      return state
  }
}

export default AttributesUser;