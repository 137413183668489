const accountStatuses = (action) => {
  switch(action){
    case "INTERNAL":
      return 'Internal'
    case "CLIENT":
      return 'Client'
    case "PARTNER":
      return 'Partner'
    case "COMPANY":
      return 'Company'
    default: 
      return ''
  }
}
const projectStatuses = (action) => {
  switch(action){
    case "ONGOING":
      return 'ongoing'
    case "COMPLETED":
      return 'completed'
     case "CANCELED":
      return 'canceled'
    default: 
      return ''
  }
}

const proposalStatuses = (action) => {
  switch(action){
    case 'DRAFT':
      return 'draft'
    case 'PENDING_APPROVAL':
      return 'pending_approval'
    case 'REJECTED_PROPOSAL':
      return 'rejected_proposal'
    case 'SIGNED_PROPOSAL':
      return 'signed_proposal'
    default: 
      return ''
  }
}

const invoiceStatuses = (action) => {
  switch(action){
    case 'PENDING':
      return 'pending'
    case 'UNASSIGNED':
      return 'unassigned'
    case 'PAID':
      return 'paid'
    default: 
      return ''
  }
}

const shootStatuses = (action) => {
  switch(action){
    case 'NEW':
      return 'new'
    case 'ASSIGNED':
      return 'assigned'
    case 'SHOOT_IN_PROGRESS':
      return 'shoot-in-progress'
    case 'EDITING':
      return 'editing'
      case 'EDITED':
        return 'edited'
    case 'READY_FOR_REVIEW':
      return 'ready-for-review'
    case 'RESHOT':
      return 'reshot'
    case 'CANCELED':
      return 'canceled'
    case 'IN_REVISION':
      return 'in-revision'
    case 'WAITING_FOR_PAYMENT':
      return 'waiting-for-payment'
    case 'COMPLETED':
      return 'completed'
    default: 
      return ''
  }
}

const albumStatuses = (action) => {
  switch(action){
    case 'UPLOAD_FINISHED':
      return 'upload-finished'
    case 'DELETED':
      return 'deleted'
    default: 
      return ''
  }
}

const rawFileStatuses = (action) => {
  switch (action) {
    case 'DRAFT':
      return 'draft'
    case 'COMPLETED':
      return 'completed'
    default:
      return ''
  }
}
const portfolioStatuses = (action) => {
  switch(action){
    case "ACTIVE":
      return 'active'
    case "FEATURED":
      return 'featured'
    case "INACTIVE":
      return 'inactive'
  }
}

const accountType = {
  INTERNAL: 'Internal',
  CLIENT: 'Client',
  PARTNER: 'Partner',
  ACCOUNT_MANAGER: 'Account Manager',
  PROJECT_MANAGER: 'Project Manager'
}

export {
  accountStatuses,
  projectStatuses,
  proposalStatuses,
  invoiceStatuses,
  shootStatuses,
  albumStatuses,
  rawFileStatuses,
  portfolioStatuses,
  accountType
}