import setUploadFile from 'Action/UploadBriefFile'
import { modifyFiles, removeFile, removeExistingFile } from 'Helpers/Utils'

const INITIAL_STATE = {
  fileProgress: {}
}

const UploadBriefFile = (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case 'SET_UPLOAD_FILE':
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.payload)
        }
      }

    case 'SET_UPLOAD_PROGRESS':
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          }
        }
      }

    case "SET_UPLOAD_PROGRESS":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          }
        }
      }

    case "SUCCESS_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 1,
          }
        }
      }

    case "FAILURE_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
          }
        }
      }

    case "REMOVE_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...removeFile(state.fileProgress, action.payload)
        }
      }
    case "REMOVE_EXISTING_FILE":
      return {
        ...state,
        ...removeExistingFile(action.payload)
      }
    default:
      return state
  }
}

export default UploadBriefFile