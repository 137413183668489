
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ user: user ,component: Component, ...rest }) => {
  return(
    <Route
      {...rest}
      render={props =>
        user.data
        ? 
          <Component {...props} />
        :
          <Redirect to="/login" />
      }
    />
  )
}
export default PrivateRoute;