import {getStorage} from 'Helpers/Function';

let permission = getStorage('permissionData') || null;

const AttributesPermission = (state = '', action) => {
  switch(action.type){
    case 'GETPERMISSION':
      return { data: permission }
    default: 
      return state
  }
}

export default AttributesPermission;