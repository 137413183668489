import React, { useEffect } from 'react';
import { accountStatuses, projectStatuses, proposalStatuses, invoiceStatuses, shootStatuses, albumStatuses, rawFileStatuses, portfolioStatuses } from './Constants';

const parse = require('html-react-parser'),
      AES = require('crypto-js/aes'),
      utf8 = require('crypto-js/enc-utf8'),
      secretKey = process.env.REACT_APP_SECRET_KEY,
      expiryTime = process.env.REACT_APP_EXPIRY_TIME,
      truncateValue = 25

const capitalizeString = (string) => {
  let dataType = typeof string
  if (dataType === "string" && string.length > 0) {
    let splitString = string.replace(/[^a-zA-Z0-9]/g, " ").split(' ');
    splitString.map((result, index)=>{
      splitString[index] = splitString[index].charAt(0).toUpperCase() + splitString[index].substring(1);   
    })
    return splitString.join(' '); 
  }
  else return string
}

const fullName = user => `${user.first_name} ${user.last_name}`

const fullPhone = user => `${user.phone_code?`${user.phone_code} `:''}${user.phone_number}`

const decorateStatus = (type, string) => {
  const account = accountStatuses,
        project = projectStatuses,
        proposal = proposalStatuses,
        invoice = invoiceStatuses,
        shoot = shootStatuses,
        album = albumStatuses,
        rawFile = rawFileStatuses,
        portfolio = portfolioStatuses;
  let klass = ''

  if(type === 'accounts'){
    if(string === account('INTERNAL')){
      klass = 'success--2';
    }
    else if(string === account('CLIENT')){
      klass = 'info';
    }
    else if(string === account('PARTNER')){
      klass = 'warning';
    }
    else if(string === account('COMPANY')){
      klass = 'success';
    }
    else{
      klass = '';
    }
  }
  else if(type === 'projects'){
    if(string === project('ONGOING')){
      klass = 'warning';
    }
    else if(string === project('COMPLETED')){
      klass = 'success';
    }
     else if(string === project('CANCELED')){
      klass = 'default';
    }
    else{
      klass = '';
    }
  }
  else if(type === 'proposals'){
    if(string === proposal('DRAFT')){
      klass = 'info';
    }
    else if(string === proposal('PENDING_APPROVAL')){
      klass = 'warning';
    }
    else if(string === proposal('REJECTED_PROPOSAL')){
      klass = 'danger';
    }
    else if(string === proposal('SIGNED_PROPOSAL')){
      klass = 'success';
    }
    else{
      klass = '';
    }
  }
  else if(type === 'invoices'){
    if(string === invoice('PENDING')){
      klass = 'warning';
    }
    else if(string === invoice('UNASSIGNED')){
      klass = 'info';
    }
    else if(string === invoice('PAID')){
      klass = 'success';
    }
    else{
      klass = '';
    }
  }
  else if(type === 'shoots'){
    if(string === shoot('NEW')){
      klass = 'info';
    }
    else if(string === shoot('ASSIGNED')){
      klass = 'success--2';
    }
    else if(string === shoot('SHOOT_IN_PROGRESS')){
      klass = 'success--2';
    }
    else if(string === shoot('EDITING')){
      klass = 'success--2';
    }
    else if(string === shoot('EDITED')){
      klass = 'success--2';
    }
    else if(string === shoot('READY_FOR_REVIEW')){
      klass = 'warning';
    }
    else if(string === shoot('RESHOT')){
      klass = 'default';
    }
    else if(string === shoot('CANCELED')){
      klass = 'default';
    }
    else if(string === shoot('IN_REVISION')){
      klass = 'warning';
    }
    else if(string === shoot('WAITING_FOR_PAYMENT')){
      klass = 'danger';
    }
    else if(string === shoot('COMPLETED')){
      klass = 'success';
    }
    else{
      klass = '';
    }
  }
  else if(type === 'albums'){
    if(string === album('UPLOAD_FINISHED')){
      klass = 'success--2';
    }
    else{
      klass = '';
    }
  }
  else if (type === 'rawFiles') {
    if (string === rawFile('DRAFT')) {
      klass = 'pending';
    }
    else if (string === shoot('COMPLETED')) {
      klass = 'success';
    }
    else {
      klass = '';
    }
  }
  else if (type === 'portfolios') {
    if (string === portfolio('ACTIVE')) {
      klass = 'success';
    }
    else if (string === portfolio('FEATURED')) {
      klass = 'info';
    }
    else if (string === portfolio('INACTIVE')) {
      klass = 'warning';
    }
    else {
      klass = '';
    }
  }
  return parse(`<span class="badge badge-${klass}">${capitalizeString(string)}</span>`)
}

const statusClassName = (klass, klassStatus, item) => {
  const classes ={
    'shoot': {
      'new': {
        'badge': 'info',
        'arrow': 'info-arrow'
      },
      'assigned': {
        'badge': 'success--2',
        'arrow': 'success--2-arrow'
      },
      'shoot-in-progress': {
        'badge': 'success--2',
        'arrow': 'success--2-arrow'
      },
      'editing': {
        'badge': 'success--2',
        'arrow': 'success--2-arrow'
      },
      'edited': {
        'badge': 'success--2',
        'arrow': 'success--2-arrow'
      },
      'ready-for-review': {
        'badge': 'warning',
        'arrow': 'warning-arrow'
      },
      'reshot': {
        'badge': 'default',
        'arrow': 'default-arrow'
      },
      'canceled': {
        'badge': 'default',
        'arrow': 'default-arrow'
      },
      'in-revision': {
        'badge': 'warning',
        'arrow': 'warning-arrow'
      },
      'waiting-for-payment': {
        'badge': 'danger',
        'arrow': 'danger-arrow'
      },
      'completed': {
        'badge': 'success',
        'arrow': 'success-arrow'
      }
    }
  }
  return classes[klass][klassStatus][item]
}


const setCustomStorage = (type, data, expiry) => {
  const now = new Date()
  const item = {
    data: data,
    expiry: now.getTime() + parseInt(expiry)
  }
  const ciphertext = AES.encrypt(JSON.stringify(item), secretKey).toString();
  
  localStorage.setItem(type, ciphertext);
}

const setStorage = (type, data) => {
  const now = new Date()
  const item = {
    data: data,
    expiry: now.getTime() + parseInt(expiryTime)
  }
  const ciphertext = AES.encrypt(JSON.stringify(item), secretKey).toString();
  
  localStorage.setItem(type, ciphertext);
}

const getStorage = (type) => {
  if(localStorage.getItem(type)){
    const now = new Date(),
          bytes = AES.decrypt(localStorage.getItem(type), secretKey),
          item =  JSON.parse(bytes.toString(utf8))
    
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(type)
		  return null
    }
    
    return item.data
  }
}

const clearStorage = () => {
  localStorage.clear();
}

function redirectToLoginPage() {
  if (window.location.href !== process.env.REACT_APP_URL) {
    window.location.href = process.env.REACT_APP_URL
  }
}

const setSession = (type, data) =>{
  window.sessionStorage.setItem(type, data);
}

const getSession = (type) => {
  return window.sessionStorage.getItem(type)
}

const removeSession = (type) => {
  window.sessionStorage.removeItem(type)
}

const clearSession = () => {
  window.sessionStorage.clear()
}

const truncate = (str) =>{
  return str.length > truncateValue ? str.substring(0, truncateValue-3) + "..." : str;
}

const fileToBase64 = (file) => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function(event) {
      resolve(event.target.result);
    };
    
    reader.readAsDataURL(file);
  })
}


const routePermission = (I, a) => {
  const permissions = getStorage('permissionData');

  let permit = permissions.map((permission, index) => {
    if(permission[a] && permission[a].includes(I)) return true
  })

  if(permit.includes(true)){
    return true
  }else{
    return false
  }
}

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  },[ref, handler]);
}

const usePreviousState = (ref, value) => {
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const percentageCalculate = (num, perNum) => {
  return (num/perNum) * 100;
}

const emailIsValid = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
}

function isExpiredSession(expiry) {
  if (expiry && (typeof expiry === 'number')) {
    let currentTime = new Date(),
      expiryInMiliseconds = expiry * 1000,
      expiryTime = new Date(expiryInMiliseconds)
    if (currentTime < expiryTime) return false
  }
  return true
}

function logout() {
  clearStorage()
  redirectToLoginPage()
}

function can(user, module, method) {
  let permissions = user.data.available_permission
  let result = permissions.map((permission) => {
    if (permission[module] && permission[module].includes(method)) return true
  })
  if (result.includes(true)) { return true }
  else return false
}


export {
        capitalizeString,
        fullName,
        fullPhone,
        decorateStatus, 
        statusClassName,
        setCustomStorage,
        setStorage, 
        getStorage,
        clearStorage,
        setSession, 
        getSession,
        removeSession,
        clearSession,
        truncate,
        fileToBase64,
        routePermission,
        useOnClickOutside,
        usePreviousState,
        percentageCalculate,
        emailIsValid,
        isExpiredSession,
        logout,
        can
      }
