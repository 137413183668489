const ClassNameReducers = (state = '', action) => {
  switch(action.type){
    case "SHOWSIDEBAR":
      return 'SidebarWrapper__Show'
    case "HIDESIDEBAR":
      return ''
    case "SHOWTABALL":
      return 'all'
    case "SHOWTABCLIENT":
      return 'client'
    case "SHOWTABCOMPANY":
      return 'company'
    case "SHOWTABPARTNER":
      return 'partner'
    case "SHOWTABINTERNAL":
      return 'internal'
    default: 
      return state
  }
}

export default ClassNameReducers;