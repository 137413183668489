import { setCustomStorage } from 'Helpers/Function';

const setUploadProgress = (id, progress) => {
  return {
    type: 'SET_UPLOAD_PROGRESS_QUOTATION',
    payload: {
      id,
      progress,
    }
  }
}

const successUploadFile = id => {
  return {
    type: 'SUCCESS_UPLOAD_FILE_QUOTATION',
    payload: id
  } 
}

const failureUploadFile = id => {
  return {
    type: 'FAILURE_UPLOAD_FILE_QUOTATION',
    payload: id
  }
}

const setUploadFile = data => {
  return {
    type: 'SET_UPLOAD_FILE_QUOTATION',
    payload: data
  }
}

const removeUploadFile = data => {
  return {
    type: 'REMOVE_UPLOAD_FILE_QUOTATION',
    payload: data
  }
}

const removeExistingFile = data => {
  return {
    type: 'REMOVE_EXISTING_FILE_QUOTATION',
    payload: data
  }
}

const uploadFile = (dispatch, files) => {
  if (files.length) {
    const fileToUpload = files.filter(file => {
      let size = file?.file?.size
      if(size) return file
    })

    fileToUpload.map(async file => {
      let size = file?.file?.size
      if(size < 10000000){
        dispatch(setUploadProgress(file.id, 100))
        dispatch(successUploadFile(file.id))
      }else{
        dispatch(failureUploadFile(file.id))
        setCustomStorage('maxUpload', 'Sorry, your file exceed the maximum upload file size.', 2000)
      }
    })
    return { type: 'PROCESSING_UPLOAD_QUOTATION' }
  }
}

export default { 
  setUploadProgress,
  successUploadFile,
  failureUploadFile,
  setUploadFile,
  removeUploadFile,
  removeExistingFile,
  uploadFile
}