import {getStorage} from 'Helpers/Function';

let module = getStorage('availableModule') || null;

const AttributesModule = (state = '', action) => {
  switch(action.type){
    case 'GETMODULE':
      return { data: module }
    default: 
      return state
  }
}

export default AttributesModule;