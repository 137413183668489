import ClassNameReducers from './ClassNameReducers'
import AttributesUser from './AttributesUser'
import AttributesModule from './AttributesModule'
import AttributesPermission from './AttributesPermission'
import UploadBriefFile from './UploadBriefFile'
import UploadQuotation from './UploadQuotation'
import UploadAssignmentBrief from './UploadAssignmentBrief'
import {combineReducers} from 'redux'

const Index = combineReducers({
  ClassNameReducers,
  AttributesUser,
  AttributesModule,
  AttributesPermission,
  UploadBriefFile,
  UploadQuotation,
  UploadAssignmentBrief
})

export default Index;