import { modifyFilesQuotation, removeFileQuotation, removeExistingFileQuotation } from 'Helpers/Utils'

const INITIAL_STATE = {
  fileProgress: {}
}

const UploadQuotationFile = (state = INITIAL_STATE, action) => {  
  switch (action.type) {
    case 'SET_UPLOAD_FILE_QUOTATION':
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFilesQuotation(state.fileProgress, action.payload)
        }
      }

    case 'SET_UPLOAD_PROGRESS_QUOTATION':
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          }
        }
      }

    case "SET_UPLOAD_PROGRESS_QUOTATION":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          }
        }
      }

    case "SUCCESS_UPLOAD_FILE_QUOTATION":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 1,
          }
        }
      }

    case "FAILURE_UPLOAD_FILE_QUOTATION":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 0,
          }
        }
      }

    case "REMOVE_UPLOAD_FILE_QUOTATION":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...removeFileQuotation(state.fileProgress, action.payload)
        }
      }
    case "REMOVE_EXISTING_FILE_QUOTATION":
      return {
        ...state,
        ...removeExistingFileQuotation(action.payload)
      }
    default:
      return state
  }
}

export default UploadQuotationFile