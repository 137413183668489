import { setCustomStorage } from 'Helpers/Function';

const setUploadProgress = (id, progress) => {
  return {
    type: 'SET_UPLOAD_PROGRESS_ASSIGNMENT_BRIEF',
    payload: {
      id,
      progress,
    }
  }
}

const successUploadFile = id => {
  return {
    type: 'SUCCESS_UPLOAD_FILE_ASSIGNMENT_BRIEF',
    payload: id
  } 
}

const failureUploadFile = id => {
  return {
    type: 'FAILURE_UPLOAD_FILE_ASSIGNMENT_BRIEF',
    payload: id
  }
}

const setUploadFile = data => {
  console.log(data)
  return {
    type: 'SET_UPLOAD_FILE_ASSIGNMENT_BRIEF',
    payload: data
  }
}

const removeUploadFile = data => {
  return {
    type: 'REMOVE_UPLOAD_FILE_ASSIGNMENT_BRIEF',
    payload: data
  }
}

const removeExistingFile = data => {
  return {
    type: 'REMOVE_EXISTING_FILE_ASSIGNMENT_BRIEF',
    payload: data
  }
}

const uploadFile = (dispatch, files) => {
  if (files.length > 0) {
    files.forEach(async file => {
      dispatch(setUploadProgress(file.id, 100))
      dispatch(successUploadFile(file.id))
    })
    return { type: 'PROCESSING_UPLOAD_ASSIGNMENT_BRIEF' }
  }
}

export default { 
  setUploadProgress,
  successUploadFile,
  failureUploadFile,
  setUploadFile,
  removeUploadFile,
  removeExistingFile,
  uploadFile
}