import { size } from 'lodash'
import { setSession, getSession } from 'Helpers/Function'

export const modifyFiles = (existingFiles, files) => {
  let fileToUpload = {}

  if(files && files.length > 0){
    for (let i = 0; i < files.length; i++) {
      const id = size(existingFiles) + i + 1
      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i],
          progress: 0
        }
      }
    }
  }
  return fileToUpload
}

export const removeFile = (existingFiles, file) => {
  let fileToUpload = {}
  if(existingFiles && Object.keys(existingFiles).length > 0){
    for (let i = 0; i < Object.keys(existingFiles).length; i++) {
      if (existingFiles[i+1].file && existingFiles[i+1].file.name == file.name){
        existingFiles[i+1] = {}
      }
    }
  }
  return fileToUpload = {
    ...existingFiles
  }
}

export const removeExistingFile = (files) => {
  let existingData = JSON.parse(getSession('removeExistingFile')), 
      fileToRemove = {},
      data = []

  fileToRemove = {
    id: files.id,
    status: 1,
    progress: 100,
    url: files.url,
    type: "form" 
  }

  if(existingData && existingData.length > 0){
    existingData.map(async(item)=>{
      if(files.id !== item.id){
        let fileToRemove = {
          id: item.id,
          status: 1,
          progress: 100,
          url: item.url,
          type: "form" 
        }
        await data.push(fileToRemove)
      }
    })
    data.push(fileToRemove)
  }else{
    data.push(fileToRemove)
  }
  
  setSession('removeExistingFile', JSON.stringify(data))
}
//quotation
export const modifyFilesQuotation = (existingFiles, files) => {
  let fileToUpload = {}

  if(files && files.length > 0){
    for (let i = 0; i < files.length; i++) {
      const id = size(existingFiles) + i + 1
      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i],
          progress: 0
        }
      }
    }
  }
  return fileToUpload
}

export const removeFileQuotation = (existingFiles, file) => {
  let fileToUpload = {}
  if(existingFiles && Object.keys(existingFiles).length > 0){
    for (let i = 0; i < Object.keys(existingFiles).length; i++) {
      if (existingFiles[i+1].file && existingFiles[i+1].file.name == file.name){
        existingFiles[i+1] = {}
      }
    }
  }
  return fileToUpload = {
    ...existingFiles
  }
}

export const removeExistingFileQuotation = (files) => {
  let existingData = JSON.parse(getSession('removeExistingFileQuotation')), 
      fileToRemove = {},
      data = []

  fileToRemove = {
    status: 1,
    progress: 100,
    url: files.url,
    type: "form" 
  }

  if(existingData && existingData.length > 0){
    existingData.map(async(item)=>{
      if(files.id !== item.id){
        let fileToRemove = {
          status: 1,
          progress: 100,
          url: item.url,
          type: "form" 
        }
        await data.push(fileToRemove)
      }
    })
    data.push(fileToRemove)
  }else{
    data.push(fileToRemove)
  }
  
  setSession('removeExistingFileQuotation', JSON.stringify(data))
}

//assignment brief
export const modifyFilesAssignmentBrief = (existingFiles, files) => {
  let fileToUpload = {}

  if(files && files.length > 0){
    for (let i = 0; i < files.length; i++) {
      const id = size(existingFiles) + i + 1
      fileToUpload = {
        ...fileToUpload,
        [id]: {
          id,
          file: files[i],
          progress: 0
        }
      }
    }
  }
  return fileToUpload
}

export const removeFileAssignmentBrief = (existingFiles, file) => {
  let fileToUpload = {}
  if(existingFiles && Object.keys(existingFiles).length > 0){
    for (let i = 0; i < Object.keys(existingFiles).length; i++) {
      if (existingFiles[i+1].file && existingFiles[i+1].file.name == file.name){
        existingFiles[i+1] = {}
      }
    }
  }
  return fileToUpload = {
    ...existingFiles
  }
}

export const removeExistingFileAssignmentBrief = (files) => {
  let existingData = JSON.parse(getSession('removeExistingFileAssignmentBrief')), 
      fileToRemove = {},
      data = []

  fileToRemove = {
    status: 1,
    progress: 100,
    url: files.url,
    type: "form" 
  }

  if(existingData && existingData.length > 0){
    existingData.map(async(item)=>{
      if(files.id !== item.id){
        let fileToRemove = {
          status: 1,
          progress: 100,
          url: item.url,
          type: "form" 
        }
        await data.push(fileToRemove)
      }
    })
    data.push(fileToRemove)
  }else{
    data.push(fileToRemove)
  }
  
  setSession('removeExistingFileAssignmentBrief', JSON.stringify(data))
}